import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { KeywordsAndConstants } from '../../../core/keywords-and-constants';
import { LocalStorageService } from '../../../network/services/local-storage.service';
import { HttpClient } from '@angular/common/http';
import { SideBarService } from '../../../network/services/sidebar.service';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { GenericListResponse } from '../../../models/generic-list-response';
import { AccessControlCategory } from '../../../models/access-control.model';
import { TokenCallbackService } from '../../../network/services/token-callback.service';
import { BaseComponent } from '../../base/base/base.component';
import MetisMenu from 'metismenujs';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { SimplebarAngularModule } from 'simplebar-angular';
import { RecursiveMenuItemComponent } from './recursive-menu-item/recursive-menu-item.component';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    RouterLink,
    CommonModule,
    SimplebarAngularModule,
    RecursiveMenuItemComponent,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild('componentRef') scrollRef: any;
  @Input() isCondensed = false;
  @ViewChild('sideMenu') sideMenu!: ElementRef;
  menu: any;
  sideBarMenuItem: any = [];

  accessControlData: any;
  accessControlList = new Array<any>();
  notificationCountsByGroup: any;
  // bsModalRef?: BsModalRef;

  constructor(
    private router: Router,
    constants: KeywordsAndConstants,
    _service: LocalStorageService,
    _callBackService: TokenCallbackService,
    http: HttpClient,
    private _sideBarService: SideBarService,
    private ref: ChangeDetectorRef,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    super(http, constants, _service, _callBackService, true, platformId);
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
        this._scrollElement();
      }
    });
  }

  ngOnInit() {
    let sideBarCallSubscription = this._callBackService
      .observeForCallingApisInSideBar()
      .subscribe((readyToCallSideBarApis: boolean) => {
        if (readyToCallSideBarApis) {
          this.callSideBarList();
          sideBarCallSubscription.unsubscribe();
        }
      });
    this.initialize();
    this._scrollElement();
    this._sideBarService.callbackResponse.subscribe((response) => {
      this.initialize();
      this.ref.detectChanges();
      this._scrollElement();
      if (this.sideMenu !== undefined) {
        this.sideMenu.nativeElement.focus();
      }
      this.menu = new MetisMenu(this.sideMenu.nativeElement);
      this._activateMenuDropdown();
    });
  }

  callSideBarList() {
    let intervalIdForInitialApiCalls = setInterval(() => {
      if (!this.pageApisCalled) {
        this.pageApisCalled = true;
        this.getSideBarList();
        this.getAllAccessControl();
        clearInterval(intervalIdForInitialApiCalls);
      }
    }, 10);
  }

  getSideBarList() {
    this._sideBarService
      .getbyCategory()
      .subscribe((res: GenericListResponse<AccessControlCategory>) => {
        const filteredAccessControlCategories = res.data.filter((item: any) => {
          return item.shouldShow;
        });
        this.constants.byCategoryValue = filteredAccessControlCategories;
        this.constants.byCategoryValue.sort((a: any, b: any) => {
          return a.indexOrder - b.indexOrder;
        });
        this.constants.byCategoryValue.forEach((e: any) => {
          this.accessControl(e);
        });
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem(
            this.constants.byCategory,
            JSON.stringify(this.constants.byCategoryValue)
          );
        }
        this.initialize();
        this.ref.detectChanges();
        this._scrollElement();
        if (this.sideMenu !== undefined) {
          this.sideMenu.nativeElement.focus();
        }
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
        this._activateMenuDropdown();
      });
  }

  accessControl(e: any) {
    var pageUrlFromAccessControlsList = '';
    e.accessControls.forEach((val: any) => {
      var method = val.method.substring(0, val.method.lastIndexOf('/') + 1);
      val.method = method.slice(0, -1);
      pageUrlFromAccessControlsList = val.method;
    });
    e.unreadCount = '';
    this.notificationCountsByGroup?.forEach((val: any) => {
      if (e.identifier == val.accessControlCategoryIdentifier) {
        e['unreadCount'] = val.count;
      }
    });
    if (pageUrlFromAccessControlsList != '') {
      pageUrlFromAccessControlsList = pageUrlFromAccessControlsList.replace(
        /[A-Z]+(?![a-z])|[A-Z]/g,
        ($, ofs) => (ofs ? '-' : '') + $.toLowerCase()
      );
      e.pageUrl = pageUrlFromAccessControlsList;
    } else {
      e.pageUrl = e.pageUrl.replace(
        /[A-Z]+(?![a-z])|[A-Z]/g,
        ($: any, ofs: any) => (ofs ? '-' : '') + $.toLowerCase()
      );
    }
    if (e.subCategories.length > 0) {
      e.subCategories = e.subCategories.filter(
        (item: any) => item.shouldShow === true
      );
      e.subCategories.sort((a: any, b: any) => {
        return a.indexOrder - b.indexOrder;
      });
      e.subCategories.forEach((subCategoriesval: any) => {
        this.accessControl(subCategoriesval);
      });
    }
  }

  getAllAccessControl() {
    this._sideBarService.getAccessControl().subscribe((res: any) => {
      this.accessControlData = res.data;
      for (let i = 0; this.accessControlData.length > i; i++) {
        this.accessControlList.push(this.accessControlData[i].name);
      }
      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem(
          this.constants.accessControlItem,
          JSON.stringify(this.accessControlList)
        );
      }
    });
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      const element = this.sideMenu.nativeElement;
      if (this.sideMenu !== undefined) {
        element.focus();
      }
      this.menu = new MetisMenu(element);
      this._activateMenuDropdown();
    }
  }

  toggleMenu(event: any) {
    event.currentTarget.nextElementSibling.classList.toggle('mm-show');
  }

  ngOnChanges() {
    if ((!this.isCondensed && this.sideMenu) || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }

  _scrollElement() {
    setTimeout(() => {
      if (isPlatformBrowser(this.platformId)) {
        if (document.getElementsByClassName('mm-active').length > 0) {
          const currentPosition: any =
            document.getElementsByClassName('mm-active')[0];
          if (currentPosition['offsetTop'] > 500)
            if (this.scrollRef.SimpleBar !== null)
              this.scrollRef.SimpleBar.getScrollElement().scrollTop =
                currentPosition['offsetTop'] + 300;
        }
      }
    }, 300);
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className: any) {
    if (isPlatformBrowser(this.platformId)) {
      const els = document.getElementsByClassName(className);
      while (els[0]) {
        els[0].classList.remove(className);
      }
    }
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    this._removeAllClass('mm-active');
    this._removeAllClass('mm-show');
    if (isPlatformBrowser(this.platformId)) {
      const links: any = document.getElementsByClassName('side-nav-link-ref');
      let menuItemEl = null;
      // tslint:disable-next-line: prefer-for-of
      const paths = [];
      for (let i = 0; i < links.length; i++) {
        paths.push(links[i]['pathname']);
      }
      var itemIndex = paths.indexOf(window.location.pathname);
      if (itemIndex === -1) {
        const strIndex = window.location.pathname.lastIndexOf('/');
        const item = window.location.pathname.substr(0, strIndex).toString();
        menuItemEl = links[paths.indexOf(item)];
      } else {
        menuItemEl = links[itemIndex];
      }
      if (menuItemEl) {
        menuItemEl.classList.add('active');
        const parentEl = menuItemEl.parentElement;
        if (parentEl) {
          parentEl.classList.add('mm-active');
          const parent2El = parentEl.parentElement.closest('ul');
          if (parent2El && parent2El.id !== 'side-menu') {
            parent2El.classList.add('mm-show');
            const parent3El = parent2El.parentElement;
            if (parent3El && parent3El.id !== 'side-menu') {
              parent3El.classList.add('mm-active');
              const childAnchor = parent3El.querySelector('.has-arrow');
              const childDropdown = parent3El.querySelector('.has-dropdown');
              if (childAnchor) {
                childAnchor.classList.add('mm-active');
              }
              if (childDropdown) {
                childDropdown.classList.add('mm-active');
              }
              const parent4El = parent3El.parentElement;
              if (parent4El && parent4El.id !== 'side-menu') {
                parent4El.classList.add('mm-show');
                const parent5El = parent4El.parentElement;
                if (parent5El && parent5El.id !== 'side-menu') {
                  parent5El.classList.add('mm-active');
                  const childanchor = parent5El.querySelector('.is-parent');
                  if (childanchor && parent5El.id !== 'side-menu') {
                    childanchor.classList.add('mm-active');
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Initialize
   */
  initialize(): void {
    if (isPlatformBrowser(this.platformId)) {
      let menuItem: any = localStorage.getItem(this.constants.byCategory);
      this.sideBarMenuItem = JSON.parse(menuItem);
    
    }
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: any) {
    return item.subCategories !== undefined
      ? item.subCategories.length > 0
      : false;
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.accessControlList = [];
    this.constants.byCategoryValue = [];
    this.accessControlData = '';
  }
}
