import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { KeywordsAndConstants } from '../../../core/keywords-and-constants';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  constructor(
    private router: Router,
    public constants: KeywordsAndConstants,
    @Inject(PLATFORM_ID) public platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem(this.constants.LocalStorageToken)) {
        this.router.navigateByUrl('/dashboard');
      }
    }
  }

  goToAdminLogin() {
    this.router.navigate(['/user/login']);
  }
}
