import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { TopbarComponent } from '../topbar/topbar.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { KeywordsAndConstants } from '../../../core/keywords-and-constants';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-vertical',
  standalone: true,
  imports: [TopbarComponent, SidebarComponent, RouterOutlet],
  templateUrl: './vertical.component.html',
  styleUrl: './vertical.component.scss',
})
export class VerticalComponent implements OnInit, AfterViewInit {
  isCondensed: any = false;


  constructor(
    private router: Router,
    private constants: KeywordsAndConstants,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          document.body.classList.remove('sidebar-enable');
        }
      }
    });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      document.body.setAttribute('data-layout', 'vertical');
    }
  }

  isMobile() {
    const ua = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
      ua
    );
  }

  ngAfterViewInit() {}

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    if (isPlatformBrowser(this.platformId)) {
      document.body.classList.toggle('right-bar-enabled');
    }
  }

  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    this.isCondensed = !this.isCondensed;
    if (isPlatformBrowser(this.platformId)) {
      document.body.classList.toggle('sidebar-enable');
      document.body.classList.toggle('vertical-collpsed');

      if (window.screen.width <= 992) {
        document.body.classList.remove('vertical-collpsed');
      }
    }
  }
}
