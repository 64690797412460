<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">    

        <a routerLink="/" class="logo logo-light">     
          <span class="logo-lg">
            <img src="assets/img/liveness-logo-whiteblue.png" alt="" height="40" style="width: 100%;">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block">
      </div>
      <div class="dropdown d-inline-block" dropdown>
        <button type="button" class="btn header-item d-flex align-items-center" dropdownToggle
          id="page-header-user-dropdown">
          <div class="avatar-xs">
            <span class="avatar-title rounded-circle bg-primary"> {{ userInfo?.name | shortName | uppercase}} </span>
          </div>
          <span class="d-none d-xl-inline-block ms-1">{{userInfo?.name}} </span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
          <!-- item-->
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            Logout</a>

        </div>
      </div>
    </div>
  </div>
</header>