import { Component } from '@angular/core';
import {  BsModalService } from 'ngx-bootstrap/modal';
import { KeywordsAndConstants } from '../../../../src/core/keywords-and-constants';
import { LocalStorageService } from '../../../../src/network/services/local-storage.service';
import { DialogUtil } from '../../../../src/utils/dialog-utils';

@Component({
  selector: 'app-under-maintenance-modal',
  templateUrl: './under-maintenance-modal.component.html',
  styleUrls: ['./under-maintenance-modal.component.scss']
})
export class UnderMaintenanceModalComponent {
  errorCode: number | undefined;
  message: string | undefined;
  remainingTime: number = 20;
  setTimmer:boolean = false;
  serverDownMessage: boolean = false;

  constructor(
    private modalService: BsModalService,
    public dialog: DialogUtil,
    public constants: KeywordsAndConstants,
    public _service: LocalStorageService,
  ){}

  ngOnInit(): void{
    if(this.errorCode==503001){
      this.setTimmer = true;
    }
    else if (!this.errorCode){
      this.remainingTime=60;
      this.setTimmer = true;
    }
    else{
      this.serverDownMessage = true;
    }
    if(this.setTimmer){
      let interval =  setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime--;
        }
        else{
          this.modalService.hide();
          localStorage.setItem(this.constants.isModalOpen,JSON.stringify(false));
          var val = localStorage.getItem(this.constants.isModalOpen)
          clearInterval(interval);
        }
      }, 1000);
    }

  }

  logout(){
    this._service.logout();
    this.modalService.hide();
  }

}
