<div id="layout-wrapper">
  <ng-container *ngIf="!auth">
    <app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()"> </app-topbar>

    <app-sidebar></app-sidebar>
  </ng-container>

  <div class="main-content" *ngIf="!auth">
    <div class="page-content">
      <!-- content -->
      <router-outlet></router-outlet>
    </div>

    <!-- footer -->
    <!-- <app-footer></app-footer> -->
  </div>
  <div class="p-5" *ngIf="auth">
    <router-outlet></router-outlet>
  </div>
</div>
