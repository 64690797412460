import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { EventService } from '../../network/services/event.service';
import {
  LAYOUT_MODE,
  LAYOUT_VERTICAL,
  LAYOUT_WIDTH,
  SIDEBAR_TYPE,
  TOPBAR,
} from './layouts.model';
import { VerticalComponent } from './vertical/vertical.component';
import { CommonModule, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-layouts',
  standalone: true,
  imports: [VerticalComponent, CommonModule],
  templateUrl: './layouts.component.html',
  styleUrl: './layouts.component.scss',
})
export class LayoutsComponent implements OnInit, AfterViewInit {
  layoutType!: string;
  layoutwidth!: string;
  topbar!: string;
  mode!: string;
  sidebartype!: string;
  constructor(
    private eventService: EventService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    // default settings
    this.layoutType = LAYOUT_VERTICAL;
    this.layoutwidth = LAYOUT_WIDTH;
    this.topbar = TOPBAR;
    this.mode = LAYOUT_MODE;
    this.sidebartype = SIDEBAR_TYPE;

    // document.body.setAttribute('data-bs-theme', this.mode);

    // listen to event and change the layout, theme, etc
    this.eventService.subscribe('changeLayout', (layout: any) => {
      this.layoutType = layout;
    });

    this.LayoutWidth(this.layoutwidth);

    this.eventService.subscribe('changeWidth', (width: any) => {
      this.layoutwidth = width;
      this.LayoutWidth(this.layoutwidth);
    });

    // listen to event and change the layout, theme, etc
    this.eventService.subscribe('changeSidebartype', (layout: any) => {
      this.sidebartype = layout;
      this.changeSidebar(this.sidebartype);
    });

    // Change Mode
    this.eventService.subscribe('changeMode', (mode: any) => {
      this.mode = mode;
      this.changeMode(this.mode);
    });

    this.changeSidebar(this.sidebartype);
    this.changeMode(this.mode);
  }
  // Theme Drk Light Mode
  changeMode(value: any) {
    if (isPlatformBrowser(this.platformId)) {
      switch (value) {
        case 'light':
          document.body.setAttribute('data-bs-theme', 'light');
          break;
        case 'dark':
          document.body.setAttribute('data-bs-theme', 'dark');
          break;
        default:
          document.body.setAttribute('data-bs-theme', 'light');
          break;
      }
    }
  }

  changeSidebar(value: any) {
    if (isPlatformBrowser(this.platformId)) {
      switch (value) {
        case 'light':
          document.body.setAttribute('data-sidebar', 'light');
          document.body.setAttribute('data-topbar', 'dark');
          document.body.removeAttribute('data-sidebar-size');
          document.body.removeAttribute('data-layout-size');
          document.body.removeAttribute('data-keep-enlarged');
          document.body.classList.remove('vertical-collpsed');
          document.body.removeAttribute('data-layout-scrollable');
          break;
        case 'compact':
          document.body.setAttribute('data-sidebar-size', 'small');
          document.body.setAttribute('data-sidebar', 'dark');
          document.body.removeAttribute('data-topbar');
          document.body.removeAttribute('data-layout-size');
          document.body.removeAttribute('data-keep-enlarged');
          document.body.classList.remove('sidebar-enable');
          document.body.classList.remove('vertical-collpsed');
          document.body.removeAttribute('data-layout-scrollable');
          break;
        case 'dark':
          document.body.setAttribute('data-sidebar', 'dark');
          document.body.removeAttribute('data-topbar');
          document.body.removeAttribute('data-layout-size');
          document.body.removeAttribute('data-keep-enlarged');
          document.body.removeAttribute('data-sidebar-size');
          document.body.classList.remove('sidebar-enable');
          document.body.classList.remove('vertical-collpsed');
          document.body.removeAttribute('data-layout-scrollable');
          break;
        case 'icon':
          document.body.classList.add('vertical-collpsed');
          document.body.setAttribute('data-sidebar', 'dark');
          document.body.removeAttribute('data-layout-size');
          document.body.setAttribute('data-keep-enlarged', 'true');
          document.body.removeAttribute('data-topbar');
          document.body.removeAttribute('data-layout-scrollable');
          break;
        case 'colored':
          document.body.classList.remove('sidebar-enable');
          document.body.classList.remove('vertical-collpsed');
          document.body.setAttribute('data-sidebar', 'colored');
          document.body.removeAttribute('data-layout-size');
          document.body.removeAttribute('data-keep-enlarged');
          document.body.removeAttribute('data-topbar');
          document.body.removeAttribute('data-layout-scrollable');
          document.body.removeAttribute('data-sidebar-size');
          break;
        default:
          document.body.setAttribute('data-sidebar', 'dark');
          break;
      }
    }
  }

  ngAfterViewInit() {}

  LayoutWidth(width: string) {
    if (isPlatformBrowser(this.platformId)) {
      switch (width) {
        case 'fluid':
          document.body.setAttribute('data-layout-size', 'fluid');
          document.body.classList.remove('vertical-collpsed');
          document.body.removeAttribute('data-layout-scrollable');
          break;
        default:
          document.body.setAttribute('data-layout-size', 'fluid');
          break;
      }
    }
  }

  /**
   * Check if the vertical layout is requested
   */
  isVerticalLayoutRequested() {
    return this.layoutType === LAYOUT_VERTICAL;
  }
}
