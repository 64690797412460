import { ErrorAction } from '../enums/error-action';

export class GenericError extends Error {
  constructor(
    public code: number | null = null,
    public override message: string = '',
    public errorAction: ErrorAction = ErrorAction.SHOW_MESSAGE_ON_ALERT
  ) {
    super(message);
  }
}
