import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
})
export class LoaderComponent {
  public isLoading = new BehaviorSubject(false);
  loading: boolean = true;

  constructor() {
    this.isLoading.subscribe((v) => {
      setTimeout(() => {
        this.loading = v;
      }, 1000);
    });
  }
  ngOnInit(): void {}
}
