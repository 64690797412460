import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { ModalArguments } from '../../../models/modal-arguments';
import { ModalButtonType } from '../../../enums/modal_button_type';
import { KeywordsAndConstants } from '../../../core/keywords-and-constants';
import { LocalStorageService } from '../../../network/services/local-storage.service';
import { AdminLoginService } from '../../../network/services/admin-login.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { DialogUtil } from '../../../utils/dialog-utils';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ShortNamePipe } from '../../pages/short-name.pipe';

@Component({
  selector: 'app-topbar',
  standalone: true,
  imports: [BsDropdownModule, CommonModule, ShortNamePipe],
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss',
})
export class TopbarComponent implements OnInit {
  @Output() mobileMenuButtonClicked = new EventEmitter();
  userInfo: any;
  response: any;
  totalCount: any;
  constructor(
    public constants: KeywordsAndConstants,
    public _localStorageService: LocalStorageService,
    private _adminLoginService: AdminLoginService,
    public dialog: DialogUtil,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    this._adminLoginService.callbackResponseForUser.subscribe((response) => {
      this.response = response;
      if (isPlatformBrowser(this.platformId)) {
        let userDetails: any = localStorage.getItem(
          this.constants.LocalStorageUserInfo
        );
        this.userInfo = JSON.parse(userDetails);
      }
    });
    if (!this.response) {
      if (isPlatformBrowser(this.platformId)) {
        let userDetails: any = localStorage.getItem(
          this.constants.LocalStorageUserInfo
        );
        this.userInfo = JSON.parse(userDetails);
      }
    }
  }

  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  logout() {
    const contextAlias = this;
    this.dialog.errorShowModal(
      new ModalArguments('Are you sure you want to logout?')
        .withButtonType(ModalButtonType.DOUBLE_BUTTON)
        .withButtonPositiveText('Logout')
        .withButtonNegativeText('Cancel')
        .withCallback({
          buttonNegativeClicked() {},
          buttonPositiveClicked() {
            contextAlias._localStorageService.logout();
          },
          buttonSingleClicked() {},
        })
    );
  }
}
