import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: '[recursive]',
  standalone: true,
  imports: [CommonModule, RouterLink],
  template: `
    <li *ngFor="let subitem of children">
      <a
        routerLink="{{ subitem.pageUrl }}"
        *ngIf="!hasItems(subitem)"
        class="side-nav-link-ref"
        [attr.data-parent]="subitem.identifier"
        routerLinkActive="active"
      >
        <span> {{ subitem.title }}</span>
        <span
          class="badge rounded-pill float-end bg-primary"
          *ngIf="subitem.unreadCount"
          >{{ subitem.unreadCount }}</span
        >
      </a>
      <a
        *ngIf="hasItems(subitem)"
        class="side-nav-link-a-ref has-arrow"
        href="javascript:void(0);"
        [attr.data-parent]="subitem.identifier"
      >
        <span> {{ subitem.title }}</span>
        <span
          class="badge rounded-pill float-end bg-primary"
          *ngIf="subitem.unreadCount"
          >{{ subitem.unreadCount }}</span
        >
      </a>
      <ul
        recursive
        [children]="subitem.subCategories"
        *ngIf="hasItems(subitem)"
        class="sub-menu mm-collapse"
        aria-expanded="false"
      ></ul>
    </li>
  `,
  styles: [``],
})
export class RecursiveMenuItemComponent implements OnInit, OnDestroy {
  @Input() title!: number;
  @Input() label!: string;
  @Input() children!: any[];
  @Input() parent: any;
  self = this;
  constructor() {}
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }
  hasItems(item: any) {
    return item.subCategories !== undefined
      ? item.subCategories.length > 0
      : false;
  }

  ngOnDestroy() {}
}
