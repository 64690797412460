<header class="py-4">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-6">
                <img class="img-fluid rounded" loading="lazy" src="../../../assets/img/liveness-logo.png" width="300"
                    alt="BootstrapBrain Logo">
            </div>
            <div class="col-6 text-end">
                <button (click)="goToAdminLogin()" class="btn btn-primary px-4">Login</button>
            </div>
        </div>
    </div>
</header>
<!-- Login 9 - Bootstrap Brain Component -->
<section class="py-5 py-md-5 py-xl-8 d-flex auth-section justify-content-center align-items-center">
    <div class="container">
        <div class="row gy-4 align-items-center">
            <div class="col-12 col-md-6 col-xl-7 order-2 order-lg-1">
                <div class="d-flex">
                    <div class="col-12 col-xl-9">
                        <h2 class="h1 mb-4 fw-bold">Verifiedge Liveness</h2>
                        <hr class="border-primary-subtle mb-4">
                        <p class="lead mb-5">
                            At ATLPay, we prioritize your security. Our multi-factor authentication process ensures
                            that only you can access your account. By combining something you know (like your
                            password) with something you have (like a verification code sent to your device), we
                            protect your information against unauthorized access.
                        </p>

                        <div class="text-endx">
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#6e90f6"
                                class="bi bi-grip-horizontal" viewBox="0 0 16 16">
                                <path
                                    d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-xl-5 order-1 order-lg-2">
                <img src="../../../assets/img/liveness.png" class="auth-image d-block d-lg-none" alt="">
            </div>
        </div>
    </div>
    <img src="../../../assets/img/liveness.png" class="auth-image d-none d-lg-flex" alt="">
</section>
