import { Routes } from '@angular/router';
import { HomeComponent } from './extrapages/home/home.component';
import { LayoutsComponent } from './layouts/layouts.component';
import { AuthGuard } from '../network/auth-guard.service';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  // {
  //   path: 'user/login',
  //   component: LoginComponent,
  // },
  // {
  //   path: 'admin/login',
  //   component: LoginComponent,
  // },
  // {
  //   path: 'master-user/login',
  //   component: LoginComponent,
  // },

  {
    path: '',
    component: LayoutsComponent,
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
    canActivate: [AuthGuard],
  },

  {
    path: '',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
  },
  { path: '**', redirectTo: '/dashboard' },
];
