
<div class="maintanance-modal-dialog">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div _ngcontent-dcp-c186="" class="card-body py-5 text-center">
                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <img class="maintanance-image" src="assets/images/maintenance.svg">                           
                            <h6 *ngIf="serverDownMessage" class="mt-minus60">{{message}}</h6>
                            <div>

                                <div class="col-lg-10" >
                                   <div class="rightdis"> Oops, unable to connect to server. please retry in : {{remainingTime}} seconds </div>
                                </div>
                                
                    <div class="d-flex gap-1 justify-content-center">

                       
                        <div class="mx-2">
                            <a class="btn btn-primary rounded-btn btn-lg" href="/dashboard">Dashboard</a>
                        </div>
                        <div class="mx-2">
                            <a (click)="logout()" class="btn btn-primary rounded-btn btn-lg">Logout</a>
                        </div>

                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
